const OrderBook = [
  {
    inputs: [{ internalType: 'address', name: 'trustedForwarder', type: 'address' }],
    stateMutability: 'nonpayable',
    type: 'constructor'
  },
  { inputs: [], name: 'AlreadyInitialized', type: 'error' },
  {
    inputs: [],
    name: 'InvalidAccess',
    type: 'error'
  },
  { inputs: [], name: 'InvalidAddr', type: 'error' },
  {
    inputs: [],
    name: 'InvalidCounterOrders',
    type: 'error'
  },
  { inputs: [], name: 'InvalidOrder', type: 'error' },
  {
    inputs: [],
    name: 'InvalidOriginDigits',
    type: 'error'
  },
  { inputs: [], name: 'InvalidPriceUpdation', type: 'error' },
  {
    inputs: [],
    name: 'NoDepositYet',
    type: 'error'
  },
  { inputs: [], name: 'NotAdmin', type: 'error' },
  {
    inputs: [],
    name: 'PendingExistingOrders',
    type: 'error'
  },
  {
    inputs: [{ internalType: 'uint256', name: 'roundId', type: 'uint256' }],
    name: 'WrongRoundID',
    type: 'error'
  },
  { inputs: [], name: 'ZeroAddress', type: 'error' },
  {
    anonymous: false,
    inputs: [{ indexed: false, internalType: 'address', name: 'account', type: 'address' }],
    name: 'Paused',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [{ indexed: false, internalType: 'address', name: 'account', type: 'address' }],
    name: 'Unpaused',
    type: 'event'
  },
  {
    inputs: [
      { internalType: 'uint256', name: '_amount', type: 'uint256' },
      {
        components: [
          {
            internalType: 'address',
            name: 'maker',
            type: 'address'
          },
          { internalType: 'uint256', name: 'timestamp', type: 'uint256' },
          {
            internalType: 'uint256',
            name: 'orderId',
            type: 'uint256'
          },
          {
            components: [
              { internalType: 'uint256', name: 'price', type: 'uint256' },
              {
                internalType: 'uint256',
                name: 'amount',
                type: 'uint256'
              },
              { internalType: 'bool', name: 'isBuy', type: 'bool' }
            ],
            internalType: 'struct Structs.Position',
            name: 'position',
            type: 'tuple'
          }
        ],
        internalType: 'struct Structs.MakerOrder[]',
        name: '_counterOrders',
        type: 'tuple[]'
      }
    ],
    name: '_checkAmounts',
    outputs: [{ internalType: 'uint8', name: '', type: 'uint8' }],
    stateMutability: 'pure',
    type: 'function'
  },
  { inputs: [], name: '_pause', outputs: [], stateMutability: 'nonpayable', type: 'function' },
  {
    inputs: [],
    name: '_paused',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [],
    name: '_unpause',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [{ internalType: 'bytes', name: '_orderData', type: 'bytes' }],
    name: 'addMakerOrder',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [{ internalType: 'bytes', name: '_tradeData', type: 'bytes' }],
    name: 'addTakerOrder',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [{ internalType: 'address', name: '', type: 'address' }],
    name: 'admins',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [{ internalType: 'uint256', name: '_orderId', type: 'uint256' }],
    name: 'fetchOrder',
    outputs: [
      {
        components: [
          { internalType: 'address', name: 'maker', type: 'address' },
          {
            internalType: 'uint256',
            name: 'timestamp',
            type: 'uint256'
          },
          { internalType: 'uint256', name: 'orderId', type: 'uint256' },
          {
            components: [
              {
                internalType: 'uint256',
                name: 'price',
                type: 'uint256'
              },
              { internalType: 'uint256', name: 'amount', type: 'uint256' },
              {
                internalType: 'bool',
                name: 'isBuy',
                type: 'bool'
              }
            ],
            internalType: 'struct Structs.Position',
            name: 'position',
            type: 'tuple'
          }
        ],
        internalType: 'struct Structs.MakerOrder',
        name: '',
        type: 'tuple'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [{ internalType: 'uint256[]', name: '_orderIds', type: 'uint256[]' }],
    name: 'fetchOrders',
    outputs: [
      {
        components: [
          { internalType: 'address', name: 'maker', type: 'address' },
          {
            internalType: 'uint256',
            name: 'timestamp',
            type: 'uint256'
          },
          { internalType: 'uint256', name: 'orderId', type: 'uint256' },
          {
            components: [
              {
                internalType: 'uint256',
                name: 'price',
                type: 'uint256'
              },
              { internalType: 'uint256', name: 'amount', type: 'uint256' },
              {
                internalType: 'bool',
                name: 'isBuy',
                type: 'bool'
              }
            ],
            internalType: 'struct Structs.Position',
            name: 'position',
            type: 'tuple'
          }
        ],
        internalType: 'struct Structs.MakerOrder[]',
        name: '',
        type: 'tuple[]'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [],
    name: 'fetchPrice',
    outputs: [{ internalType: 'uint256', name: 'price', type: 'uint256' }],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      {
        components: [
          { internalType: 'address', name: 'maker', type: 'address' },
          {
            internalType: 'uint256',
            name: 'timestamp',
            type: 'uint256'
          },
          { internalType: 'uint256', name: 'orderId', type: 'uint256' },
          {
            components: [
              {
                internalType: 'uint256',
                name: 'price',
                type: 'uint256'
              },
              { internalType: 'uint256', name: 'amount', type: 'uint256' },
              {
                internalType: 'bool',
                name: 'isBuy',
                type: 'bool'
              }
            ],
            internalType: 'struct Structs.Position',
            name: 'position',
            type: 'tuple'
          }
        ],
        internalType: 'struct Structs.MakerOrder[]',
        name: '_counterOrderStructure',
        type: 'tuple[]'
      }
    ],
    name: 'fetchVaults',
    outputs: [{ internalType: 'address[]', name: '', type: 'address[]' }],
    stateMutability: 'pure',
    type: 'function'
  },
  {
    inputs: [],
    name: 'getIndexPrice',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [],
    name: 'getIndexPriceInBase',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [],
    name: 'getMarkPrice',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [],
    name: 'getQuoteLot',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [],
    name: 'highestBid',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [],
    name: 'indexPrice',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [{ internalType: 'uint256', name: '_markPrice', type: 'uint256' }],
    name: 'initMarkPrice',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [{ internalType: 'address', name: 'forwarder', type: 'address' }],
    name: 'isTrustedForwarder',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [],
    name: 'lastRoundPrice',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [],
    name: 'lastSuccessfulRoundID',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [],
    name: 'lowestAsk',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [],
    name: 'marginLedger',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [],
    name: 'markPrice',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [{ internalType: 'address', name: '', type: 'address' }],
    name: 'openOrdersCount',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [],
    name: 'oracleContract',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [],
    name: 'orderId',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    name: 'orderInfo',
    outputs: [
      { internalType: 'address', name: 'maker', type: 'address' },
      {
        internalType: 'uint256',
        name: 'timestamp',
        type: 'uint256'
      },
      { internalType: 'uint256', name: 'orderId', type: 'uint256' },
      {
        components: [
          {
            internalType: 'uint256',
            name: 'price',
            type: 'uint256'
          },
          { internalType: 'uint256', name: 'amount', type: 'uint256' },
          { internalType: 'bool', name: 'isBuy', type: 'bool' }
        ],
        internalType: 'struct Structs.Position',
        name: 'position',
        type: 'tuple'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [],
    name: 'paused',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [],
    name: 'quoteLot',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [{ internalType: 'address', name: '_addr', type: 'address' }],
    name: 'removeAdmin',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [{ internalType: 'uint256', name: '_orderId', type: 'uint256' }],
    name: 'removeOrder',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [{ internalType: 'address', name: '_toCheck', type: 'address' }],
    name: 'revertifAnyOpenOrder',
    outputs: [],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [{ internalType: 'address', name: '_newAdmin', type: 'address' }],
    name: 'setAdmin',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [{ internalType: 'address', name: '_marginAddr', type: 'address' }],
    name: 'setMarginLedger',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [{ internalType: 'address', name: '_oracle', type: 'address' }],
    name: 'setOracle',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [{ internalType: 'address', name: '_addr', type: 'address' }],
    name: 'settleAccount',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [{ internalType: 'uint256', name: '_index', type: 'uint256' }],
    name: 'updateIndexPrice',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      { internalType: 'uint256', name: '_orderId', type: 'uint256' },
      {
        internalType: 'bytes',
        name: '_orderData',
        type: 'bytes'
      }
    ],
    name: 'updateMakerOrder',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [{ internalType: 'uint256', name: '_newQuoteLot', type: 'uint256' }],
    name: 'updateQuoteLot',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'uint256', name: 'price', type: 'uint256' },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'timestamp',
        type: 'uint256'
      }
    ],
    name: 'IndexPriceSet',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'address', name: 'maker', type: 'address' },
      {
        indexed: true,
        internalType: 'uint256',
        name: 'orderId',
        type: 'uint256'
      },
      { indexed: false, internalType: 'uint256', name: 'amount', type: 'uint256' },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'price',
        type: 'uint256'
      },
      { indexed: false, internalType: 'bool', name: 'isbuy', type: 'bool' }
    ],
    name: 'MakerOrderAdded',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'address', name: 'taker', type: 'address' },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'amount',
        type: 'uint256'
      },
      { indexed: false, internalType: 'uint256', name: 'price', type: 'uint256' },
      {
        indexed: false,
        internalType: 'bool',
        name: 'isbuy',
        type: 'bool'
      },
      { indexed: false, internalType: 'uint256[]', name: 'counterOrderIds', type: 'uint256[]' }
    ],
    name: 'TakerOrderCompleted',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [{ indexed: true, internalType: 'uint256', name: 'orderId', type: 'uint256' }],
    name: 'OrderExecuted',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [{ indexed: true, internalType: 'uint256', name: 'orderId', type: 'uint256' }],
    name: 'OrderRemoved',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'address', name: 'maker', type: 'address' },
      {
        indexed: true,
        internalType: 'uint256',
        name: 'orderID',
        type: 'uint256'
      },
      { indexed: false, internalType: 'uint256', name: 'amount', type: 'uint256' },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'price',
        type: 'uint256'
      },
      { indexed: false, internalType: 'bool', name: 'isbuy', type: 'bool' }
    ],
    name: 'OrderUpdated',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'uint256', name: 'orderId', type: 'uint256' },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'amountExecuted',
        type: 'uint256'
      }
    ],
    name: 'PartialOrderExecuted',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'uint256', name: 'newPrice', type: 'uint256' },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'timestamp',
        type: 'uint256'
      }
    ],
    name: 'LowestAskSet',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'uint256', name: 'newPrice', type: 'uint256' },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'timestamp',
        type: 'uint256'
      }
    ],
    name: 'HighestBidSet',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'uint256', name: 'price', type: 'uint256' },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'roundId',
        type: 'uint256'
      }
    ],
    name: 'PriceFetched',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'uint256', name: 'newPrice', type: 'uint256' },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'timestamp',
        type: 'uint256'
      }
    ],
    name: 'MarkPriceUpdated',
    type: 'event'
  }
] as const;

export { OrderBook };

export default OrderBook;
