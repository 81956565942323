import React, { useState, useEffect, useCallback } from 'react';
import LoadingSkeleton from 'components/Animation/LoadingSkeleton';
import LabelWethIcon from 'components/Common/Units/LabelWethIcon';
import LabelSnvIcon from 'components/Common/Units/LabelSnvIcon';
import { IoCopyOutline } from "react-icons/io5";
import Tooltip from '@mui/material/Tooltip';
import { useMarkPrice, useUsdEthPrice } from 'store';
import Button from 'components/Common/Button/Button';
import { useAccount, useContractWrite, useWaitForTransaction, useContractRead } from 'wagmi';
import { TransactionExecutionError } from 'viem';
import { useToastContext, NotificationActions } from 'context/NotificationContext/NotificationContext';
import { MessageHeader, getNotificationMessages } from 'utils';
import { CHAIN_ID, CONTRACT_ADDRESSES } from 'config/constants';
import { Margin } from 'assets/abi';

interface LeaderboardTableRowProps {
    item: any;
    loading: boolean;
}

const LeaderboardTableRow: React.FC<LeaderboardTableRowProps> = ({ item, loading }) => {
    const [tooltipTitle, setTooltipTitle] = useState('Copy address');
    const [liquidateHash, setLiquidateHash] = useState<`0x${string}` | undefined>(undefined);
    const toastContext = useToastContext();
    const notificationMessage = getNotificationMessages(MessageHeader.LIQUIDATE);
    const LIQUIDATE_CONTRACT_ADDRESS = CONTRACT_ADDRESSES[CHAIN_ID].Margin;
    const { usdEthPrice } = useUsdEthPrice();
    const { markPrice } = useMarkPrice();
    const [liqStatus, setLiquidateAcctStatus] = useState(false);
    const { address, isConnected } = useAccount();

    const handleCopyAddress = (useraddr: string) => {
        navigator.clipboard.writeText(useraddr);
        setTooltipTitle('Copied');
        setTimeout(() => setTooltipTitle('Copy address'), 2000);
    };
    
    // const { data: liquidateStatus, isLoading: isCanLiquidateLoading, refetch: refetchCanLiquidate } = useContractRead({
    //     address: LIQUIDATE_CONTRACT_ADDRESS,
    //     functionName: 'canLiquidate',
    //     abi: Margin,
    //     args: address && isConnected && item?.user ? [address as `0x${string}`, item.user] : undefined,
    //     enabled: !!address && isConnected && !!item?.user,
    //     staleTime: 40_000,
    //     cacheTime: 90_000
    // });
    // console.log("Contract call parameters:",{
    //     address: LIQUIDATE_CONTRACT_ADDRESS,
    //     functionName: 'canLiquidate',
    //     args: address && isConnected && item?.user ? [address as `0x${string}`, item.user] : undefined,
    //     enabled: !!address && isConnected && !!item?.user,
    //   })
    
    //   console.log("liquidateStatus", liquidateStatus)
    //   console.log("isCanLiquidateLoading", isCanLiquidateLoading)
    // const checkAndRefetch = useCallback(async () => {
    //     if (address && isConnected && refetchCanLiquidate && !isCanLiquidateLoading && item?.currentMR !== undefined) {
    //         if (item.currentMR > 0 && item.currentMR < 500) {
    //             try {
    //                 await refetchCanLiquidate();
    //             } catch (error) {
    //                 console.error('Error refetching canLiquidate:', error);
    //             }
    //         }
    //     }
    // }, [address, isConnected, refetchCanLiquidate, isCanLiquidateLoading, item]);

    // useEffect(() => {
    //     checkAndRefetch();
    // }, [checkAndRefetch]);

    // useEffect(() => {
    //     setLiquidateAcctStatus(!!liquidateStatus);
    // }, [liquidateStatus]);

    const { data, write } = useContractWrite({
        address: LIQUIDATE_CONTRACT_ADDRESS,
        functionName: 'liquidate',
        abi: Margin,
        args: address && item?.user ? [address as `0x${string}`, item.user] : undefined,
        onError(error: any) {
            const err = error as TransactionExecutionError;
            toastContext?.toastDispatch({
                type: NotificationActions.ADD,
                payload: {
                    type: 'Error',
                    messageHead: MessageHeader.LIQUIDATE,
                    content: err.shortMessage === 'User rejected the request.'
                        ? notificationMessage.userRejected
                        : notificationMessage.errorMessage
                }
            });
        },
        onSuccess: async (data: any) => {
            if (data) {
                setLiquidateHash(data?.hash);
            }
        }
    });

    const { isSuccess: isLiquidationSuccess } = useWaitForTransaction({ hash: liquidateHash });

    useEffect(() => {
        if (isLiquidationSuccess) {
            toastContext?.toastDispatch({
                type: NotificationActions.ADD,
                payload: {
                    type: 'Success',
                    messageHead: MessageHeader.LIQUIDATE,
                    content: notificationMessage.successMessage,
                    data: data?.hash,
                    isTransaction: true
                }
            });
        }
    }, [isLiquidationSuccess, data?.hash, toastContext, notificationMessage.successMessage]);

    if (loading) {
        return (
            <tr className="bg-[#10141B] border-b border-[#2c2d3a]">
                <td colSpan={7} className="py-2 px-4">
                    <LoadingSkeleton />
                </td>
            </tr>
        );
    }

    if (!item) {
        return null;
    }
useEffect(()=> {
    //console.log("item.canLiquidate", item.canLiquidate);
    setLiquidateAcctStatus(item.canLiquidate);
},[item.canLiquidate])
    return (
        <tr className="bg-[#10141B] border-b border-[#2c2d3a]">
            <td className="py-2 px-4">
                <div className="flex items-center">
                    <span className="font-normal text-[#BDB4F5]">
                        {`${item.user?.slice(0, 6) || ''}...${item.user?.slice(-4) || ''}`}
                    </span>
                    <Tooltip title={tooltipTitle} placement="top">
                        <span 
                            className="cursor-pointer ml-8 flex items-center justify-center" 
                            onClick={() => handleCopyAddress(item.user || '')}
                        >
                            <IoCopyOutline size={14} className="text-[#BDB4F5] hover:text-white transition-colors" />
                        </span>
                    </Tooltip>
                </div>
            </td>
            <td className="py-2 px-4">
                <div className="flex items-center">
                    <span className="mr-1">{item.position || 'N/A'}</span>
                    <LabelSnvIcon />
                </div>
            </td>
            <td className="py-2 px-4">
                ${((item.position || 0) * (usdEthPrice || 0) * (markPrice || 0)).toFixed(2)}
            </td>
            <td className="py-2 px-4">{item.side || '-'}</td>
            <td className="py-2 px-4">{item.mr || 0}</td>
            <td className="py-2 px-4">
                <div className="flex items-center">
                    <span className="mr-1">{item.margin || '0.00'}</span>
                    <LabelWethIcon height={14} />
                </div>
            </td>
            <td className="py-2 px-4">
                <Button
                    className={`rounded-md flex justify-center items-center py-1 px-2 text-[0.75rem] focus:outline-none transition-all duration-300 ${
                        liqStatus 
                        ? 'bg-purple-600 hover:bg-purple-700 cursor-pointer' 
                        : 'bg-purple-400 cursor-not-allowed'
                    }`}
                    disabled={!liqStatus}
                    onClick={() => {
                        if (liqStatus && write) {
                            write();
                        } else {
                            toastContext?.toastDispatch({
                                type: NotificationActions.ADD,
                                payload: {
                                    type: 'Error',
                                    messageHead: MessageHeader.LIQUIDATE,
                                    content: 'Cannot liquidate for mark price > MMF'
                                }
                            });
                        }
                    }}
                >
                    <span className={`font-medium ${liqStatus ? 'text-white' : 'text-gray-400'}`}>
                        Liquidate
                    </span>
                </Button>
            </td>
        </tr>
    );
};

export default LeaderboardTableRow;