const Margin = [
  {
    inputs: [
      {
        internalType: 'address',
        name: '_protocolVault',
        type: 'address'
      },
      {
        internalType: 'address',
        name: '_liquidationHelper',
        type: 'address'
      },
      {
        internalType: 'uint256',
        name: '_makerFees',
        type: 'uint256'
      },
      {
        internalType: 'uint256',
        name: '_takerFees',
        type: 'uint256'
      },
      {
        internalType: 'uint256',
        name: '_imf',
        type: 'uint256'
      },
      {
        internalType: 'uint256',
        name: '_mmf',
        type: 'uint256'
      },
      {
        internalType: 'uint256',
        name: '_liquidationPenalty',
        type: 'uint256'
      },
      {
        internalType: 'uint256',
        name: '_protocolFeeShare',
        type: 'uint256'
      }
    ],
    stateMutability: 'nonpayable',
    type: 'constructor'
  },
  {
    inputs: [],
    name: 'AccountNotUnderwater',
    type: 'error'
  },
  {
    inputs: [],
    name: 'FundingPeriodMismatch',
    type: 'error'
  },
  {
    inputs: [],
    name: 'IMFError',
    type: 'error'
  },
  {
    inputs: [],
    name: 'InvalidAccess',
    type: 'error'
  },
  {
    inputs: [],
    name: 'InvalidAddr',
    type: 'error'
  },
  {
    inputs: [],
    name: 'InvalidMarginAdded',
    type: 'error'
  },
  {
    inputs: [],
    name: 'InvalidParams',
    type: 'error'
  },
  {
    inputs: [],
    name: 'MMFError',
    type: 'error'
  },
  {
    inputs: [],
    name: 'NotAdmin',
    type: 'error'
  },
  {
    inputs: [],
    name: 'ZeroAddress',
    type: 'error'
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'address',
        name: 'account',
        type: 'address'
      }
    ],
    name: 'Paused',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'address',
        name: 'account',
        type: 'address'
      }
    ],
    name: 'Unpaused',
    type: 'event'
  },
  {
    inputs: [],
    name: 'IntialMarginFraction',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [],
    name: 'MaintenanceMarginFraction',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [],
    name: '_pause',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [],
    name: '_paused',
    outputs: [
      {
        internalType: 'bool',
        name: '',
        type: 'bool'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [],
    name: '_unpause',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address'
      }
    ],
    name: 'admins',
    outputs: [
      {
        internalType: 'bool',
        name: '',
        type: 'bool'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address'
      }
    ],
    name: 'balances',
    outputs: [
      {
        internalType: 'bool',
        name: 'isMarginPositive',
        type: 'bool'
      },
      {
        internalType: 'bool',
        name: 'isBuy',
        type: 'bool'
      },
      {
        internalType: 'uint256',
        name: 'margin',
        type: 'uint256'
      },
      {
        internalType: 'uint256',
        name: 'position',
        type: 'uint256'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'account',
        type: 'address'
      },
      {
        internalType: 'uint256',
        name: 'amount',
        type: 'uint256'
      }
    ],
    name: 'bringOverWater',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address'
      }
    ],
    name: 'cachedIndex',
    outputs: [
      {
        internalType: 'int256',
        name: '',
        type: 'int256'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [],
    name: 'calculateGlobalIndex',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: '_user',
        type: 'address'
      },
      {
        internalType: 'uint256',
        name: '_price',
        type: 'uint256'
      }
    ],
    name: 'calculateUserMarginRatio',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'liquidator',
        type: 'address'
      },
      {
        internalType: 'address',
        name: 'liquidatee',
        type: 'address'
      }
    ],
    name: 'canLiquidate',
    outputs: [
      {
        internalType: 'bool',
        name: '',
        type: 'bool'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: 'address',
            name: 'maker',
            type: 'address'
          },
          {
            internalType: 'uint256',
            name: 'timestamp',
            type: 'uint256'
          },
          {
            internalType: 'uint256',
            name: 'orderId',
            type: 'uint256'
          },
          {
            components: [
              {
                internalType: 'uint256',
                name: 'price',
                type: 'uint256'
              },
              {
                internalType: 'uint256',
                name: 'amount',
                type: 'uint256'
              },
              {
                internalType: 'bool',
                name: 'isBuy',
                type: 'bool'
              }
            ],
            internalType: 'struct Structs.Position',
            name: 'position',
            type: 'tuple'
          }
        ],
        internalType: 'struct Structs.MakerOrder[]',
        name: '_orders',
        type: 'tuple[]'
      }
    ],
    name: 'chargeMakerFees',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: '_taker',
        type: 'address'
      },
      {
        internalType: 'uint256',
        name: '_amount',
        type: 'uint256'
      },
      {
        internalType: 'uint256',
        name: '_price',
        type: 'uint256'
      }
    ],
    name: 'chargeTakerFees',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: 'bool',
            name: 'isMarginPositive',
            type: 'bool'
          },
          {
            internalType: 'bool',
            name: 'isBuy',
            type: 'bool'
          },
          {
            internalType: 'uint256',
            name: 'margin',
            type: 'uint256'
          },
          {
            internalType: 'uint256',
            name: 'position',
            type: 'uint256'
          }
        ],
        internalType: 'struct Structs.Balance',
        name: '_bal',
        type: 'tuple'
      },
      {
        internalType: 'bool',
        name: '_isBuy',
        type: 'bool'
      },
      {
        internalType: 'uint256',
        name: '_amount',
        type: 'uint256'
      },
      {
        internalType: 'uint256',
        name: '_price',
        type: 'uint256'
      }
    ],
    name: 'checkInitialMarginFraction',
    outputs: [
      {
        internalType: 'bool',
        name: '',
        type: 'bool'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: '_user',
        type: 'address'
      },
      {
        internalType: 'bool',
        name: '_isBuy',
        type: 'bool'
      },
      {
        internalType: 'uint256',
        name: '_amount',
        type: 'uint256'
      },
      {
        internalType: 'uint256',
        name: '_price',
        type: 'uint256'
      }
    ],
    name: 'checkInitialMarginFraction',
    outputs: [
      {
        internalType: 'bool',
        name: '',
        type: 'bool'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'user',
        type: 'address'
      }
    ],
    name: 'checkWithdrawableAmount',
    outputs: [
      {
        internalType: 'uint256',
        name: 'withdrawAmount',
        type: 'uint256'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [],
    name: 'decimalPrecision',
    outputs: [
      {
        internalType: 'uint32',
        name: '',
        type: 'uint32'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: '_user',
        type: 'address'
      },
      {
        internalType: 'uint256',
        name: '_amount',
        type: 'uint256'
      }
    ],
    name: 'depositMargin',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'account',
        type: 'address'
      }
    ],
    name: 'getAccountStatus',
    outputs: [
      {
        internalType: 'string',
        name: '',
        type: 'string'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: '_user',
        type: 'address'
      }
    ],
    name: 'getFundingDelta',
    outputs: [
      {
        internalType: 'uint256',
        name: 'delta',
        type: 'uint256'
      },
      {
        internalType: 'bool',
        name: 'isRecieved',
        type: 'bool'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [],
    name: 'getGlobalIndex',
    outputs: [
      {
        internalType: 'uint256',
        name: 'timestamp',
        type: 'uint256'
      },
      {
        internalType: 'int256',
        name: 'value',
        type: 'int256'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: '_user',
        type: 'address'
      }
    ],
    name: 'getIndexDelta',
    outputs: [
      {
        internalType: 'uint256',
        name: 'delta',
        type: 'uint256'
      },
      {
        internalType: 'bool',
        name: 'isPositive',
        type: 'bool'
      },
      {
        internalType: 'uint256',
        name: 'timestamp',
        type: 'uint256'
      },
      {
        internalType: 'int256',
        name: 'globalIndex',
        type: 'int256'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [],
    name: 'getLiquidatorShareFactor',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: '_user',
        type: 'address'
      }
    ],
    name: 'getMargin',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: '_user',
        type: 'address'
      }
    ],
    name: 'getMarginBalance',
    outputs: [
      {
        components: [
          {
            internalType: 'bool',
            name: 'isMarginPositive',
            type: 'bool'
          },
          {
            internalType: 'bool',
            name: 'isBuy',
            type: 'bool'
          },
          {
            internalType: 'uint256',
            name: 'margin',
            type: 'uint256'
          },
          {
            internalType: 'uint256',
            name: 'position',
            type: 'uint256'
          }
        ],
        internalType: 'struct Structs.Balance',
        name: '',
        type: 'tuple'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: '_user',
        type: 'address'
      }
    ],
    name: 'getMarginRatio',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: '_user',
        type: 'address'
      }
    ],
    name: 'getMarginRatioAfterSettlement',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: '_user',
        type: 'address'
      }
    ],
    name: 'getPosNeg',
    outputs: [
      {
        internalType: 'uint256',
        name: 'positive',
        type: 'uint256'
      },
      {
        internalType: 'uint256',
        name: 'negative',
        type: 'uint256'
      }
    ],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'underwaterAccount',
        type: 'address'
      },
      {
        internalType: 'uint256',
        name: 'newMR',
        type: 'uint256'
      }
    ],
    name: 'getUnderwaterAmount',
    outputs: [
      {
        internalType: 'uint256',
        name: 'deltaAmount',
        type: 'uint256'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [],
    name: 'globalIndex',
    outputs: [
      {
        internalType: 'uint256',
        name: 'timestamp',
        type: 'uint256'
      },
      {
        internalType: 'int256',
        name: 'value',
        type: 'int256'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'user',
        type: 'address'
      }
    ],
    name: 'isUnderwater',
    outputs: [
      {
        internalType: 'bool',
        name: '',
        type: 'bool'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: '_liquidator',
        type: 'address'
      },
      {
        internalType: 'address',
        name: '_liquid',
        type: 'address'
      }
    ],
    name: 'liquidate',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [],
    name: 'liquidationHelper',
    outputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [],
    name: 'liquidationPenalty',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [],
    name: 'makerFees',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [],
    name: 'orderBookAddr',
    outputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [],
    name: 'paused',
    outputs: [
      {
        internalType: 'bool',
        name: '',
        type: 'bool'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [],
    name: 'protocolShare',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [],
    name: 'protocolVault',
    outputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: 'bool',
            name: 'isMarginPositive',
            type: 'bool'
          },
          {
            internalType: 'bool',
            name: 'isBuy',
            type: 'bool'
          },
          {
            internalType: 'uint256',
            name: 'margin',
            type: 'uint256'
          },
          {
            internalType: 'uint256',
            name: 'position',
            type: 'uint256'
          }
        ],
        internalType: 'struct Structs.Balance',
        name: '_balance',
        type: 'tuple'
      },
      {
        internalType: 'address',
        name: '_user',
        type: 'address'
      }
    ],
    name: 'psuedoSettleAccount',
    outputs: [],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: '_addr',
        type: 'address'
      }
    ],
    name: 'removeAdmin',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: '_newAdmin',
        type: 'address'
      }
    ],
    name: 'setAdmin',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: '_orderBook',
        type: 'address'
      }
    ],
    name: 'setOrderBook',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: '_user',
        type: 'address'
      }
    ],
    name: 'settleAccount',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [],
    name: 'takerFees',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [],
    name: 'timeConstant',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: '_user',
        type: 'address'
      },
      {
        internalType: 'uint256',
        name: '_price',
        type: 'uint256'
      },
      {
        internalType: 'uint256',
        name: '_pAmount',
        type: 'uint256'
      },
      {
        internalType: 'bool',
        name: '_isbuy',
        type: 'bool'
      }
    ],
    name: 'updateBalance',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: '_newIMF',
        type: 'uint256'
      }
    ],
    name: 'updateIMF',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: '_new',
        type: 'uint256'
      }
    ],
    name: 'updateLiquidationPenalty',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: '_newMMF',
        type: 'uint256'
      }
    ],
    name: 'updateMMF',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: '_newMakerFees',
        type: 'uint256'
      }
    ],
    name: 'updateMakerFees',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: '_newShare',
        type: 'uint256'
      }
    ],
    name: 'updateProtocolFeesShare',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: '_newTakerFees',
        type: 'uint256'
      }
    ],
    name: 'updateTakerFees',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: '_user',
        type: 'address'
      },
      {
        internalType: 'uint256',
        name: '_amount',
        type: 'uint256'
      }
    ],
    name: 'withdrawMargin',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  }
] as const;
export { Margin };
export default Margin;
