export enum MessageHeader {
    
    LIQUIDATE = 'Liquidate',
    DEFAULT = 'Default'
}

const USER_REJECTED_MESSAGE = `Whoa! You declined to sign the transaction. Please try again when you're ready`;

const messageDescriptions: {
    [header in MessageHeader]: { errorMessage: string; successMessage: string };
} = {
    
    'Liquidate': {
        errorMessage: `Oops! Try one more time!`,
        successMessage: `Congrats! You've successfully Liquidated the account. Awesome move!`
    },
    Default: {
        errorMessage: `Whoops! Something unexpected happened. Try it again`,
        successMessage: `Hooray! Congrats on the success. You're a star!`
    }
};

export default function getNotificationMessages(header: MessageHeader) {
    for (const [key, value] of Object.entries(messageDescriptions)) {
        if (key === header) return { ...value, userRejected: USER_REJECTED_MESSAGE };
    }
    return { ...messageDescriptions.Default, userRejected: USER_REJECTED_MESSAGE };
}
