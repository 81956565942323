import { useEffect, useState } from 'react';
import { nanoid } from 'nanoid';
import clsx from 'clsx';
import { NavLink, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as AssetsIcon } from 'assets/svg/assets-icon.svg';
import { ReactComponent as AssetsIconActive } from 'assets/svg/assets-icon-active.svg';
import { useScreenWidthContext } from 'context';

export const navItems = [
    {
        id: nanoid(),
        label: 'Liquidation Dashboard',
        toLink: 'details',
        activeIcon: AssetsIconActive,
        inActiveIcon: AssetsIcon
    },
];

const LiquidationDashboardPage = () => {
    const [ isOpen, setIsOpen ] = useState<boolean>(false);
    const [activeLink, setActiveLink] = useState(0);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [hoverProp, setHoverProp] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const width = useScreenWidthContext();
    //const ribbonContext = useRibbonContext();

    const activeLinkHandler = (idx: number) => {
        setActiveLink(idx);
    };

    useEffect(() => {
        switch (location.pathname) {
            case '/liqdashboard':
                navigate('/liqdashboard/details');
                break;
            case '/liqdashboard/details':
                setActiveLink(0);
                break;

        }        
    }, [location.pathname, width]);
    return (
        <>
            {(
                <div className={`flex h-full`}>
                    <div
                        className={clsx(
                            isOpen ? 'sidebar-open' : 'sidebar-close',
                            ` bg-blivBg-900 flex flex-col gap-3 ${'h-[calc(100vh-65px)]'}`
                        )}
                        onMouseOver={() => setIsOpen(true)}
                        onMouseLeave={() => setIsOpen(false)}
                    >
                        {navItems.map((item, idx) => (
                            <NavLink key={item.id} to={item.toLink} onClick={() => activeLinkHandler(idx)}>
                                <div
                                    className={`${
                                        activeLink === idx && 'active-navlink-bg'
                                    } flex gap-6 h-[2.8rem] transition-all items-center hover:bg-[#d9d9d938] hover:rounded-r-[6px] pl-[25px] mt-[4px] max-w-[190px] min-w-[100px] overflow-hidden`}
                                    onMouseOver={() => setHoverProp(true)}
                                    onMouseLeave={() => setHoverProp(false)}
                                >
                                    {activeLink === idx ? (
                                        <span>
                                            {<item.activeIcon style={{ height: '20px', width: '20px' }} />}
                                        </span>
                                    ) : (
                                        <span>
                                            {<item.inActiveIcon style={{ height: '20px', width: '20px' }} />}
                                        </span>
                                    )}
                                    {isOpen && (
                                        <span
                                            className={
                                                activeLink === idx
                                                    ? 'text-white text-[14px] max-w-[130px] min-w-[130px] overflow-hidden font-semibold product-name '
                                                    : 'text-[14px] font-medium product-name max-w-[130px] min-w-[130px] overflow-hidden text-[#6F6E84] '
                                            }
                                        >
                                            {item.label}
                                        </span>
                                    )}
                                </div>
                            </NavLink>
                        ))}
                    </div>
                    <div
                        className={clsx(
                            isOpen ? 'sidebar-open-outlet' : 'sidebar-close-outlet',
                            `bg-blivBg-950 h-full`
                        )}
                    >
                        <Outlet />
                    </div>
                </div>
            )}
        </>
    );
};
export default LiquidationDashboardPage;