import LiquidationTablePage from './LiquidationTablePage';
import { useEffect, useState, useCallback, useRef, useMemo } from 'react';
import { liquidationSystemApi } from 'config/axios';
import { useItemsPage, useSelectedPage, useActiveTab, useOrder } from 'store';
import { CHAIN_ID, CONTRACT_ADDRESSES } from 'config/constants';
import { Margin } from 'assets/abi';
import { useAccount, useContractReads } from 'wagmi';
import { Abi } from 'viem';

interface Account {
  id: number;
  useraddr: string;
  quantity: string;
  side: string;
  avgbp: string;
}

interface UserPosition {
  user: string;
  position: string;
  margin: string;
  side: string;
  mr: number;
  canLiquidate: boolean;
}

interface Pagination {
  currentPage: number;
  totalPages: number;
  totalItems: number;
  itemsPerPage: number;
}

interface GetUserPositionsResponse {
  data: UserPosition[];
  pagination: Pagination;
}
//const ITEMS_PER_PAGE = Math.floor((window.innerHeight - 520) / 42.2);
const LiquidationPage = () => {
    const [positions, setPositions] = useState<UserPosition[]>([]);
    const [pagination, setPagination] = useState<Pagination>({
      currentPage: 1,
      totalPages: 1,
      totalItems: 0,
      itemsPerPage: 0
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const loadMoreRef = useRef(false);
    const { itemsPerPage } = useItemsPage();
    const { selectedPage } = useSelectedPage();
    const { address, isConnected } = useAccount();
    const { activeTab } = useActiveTab();
    const { direction } = useOrder();

    const LIQUIDATE_CONTRACT_ADDRESS = CONTRACT_ADDRESSES[CHAIN_ID].Margin;
    
    const fetchPositions = useCallback(async (page: number, limit: number, activeTab: string, order: number): Promise<GetUserPositionsResponse> => {
      try {
        const endpoint = activeTab === 'underwater' ? '/getUnderwaterPositions' : '/getLiquidatablePositions';
        console.log("ITEMS_PER_PAGE, page",limit,page, activeTab);       
    
        const response = await liquidationSystemApi.get(
          `${endpoint}?limit=${limit}&page=${page+1}&direction=${order}`
        );
        console.log("response",response,liquidationSystemApi,endpoint);
      //   const response = await liquidationSystemApi.get(
      //     `/getLiquidatablePositions?limit=${limit}&page=${page+1}`
      // );
        return response.data;
      } catch (error) {
        console.error('Error fetching positions:', error);
        throw error;
      }
    }, []);
  
    const loadMoreData = useCallback((page: number, limit: number, activeTab: string, order: number) => {
      if (loadMoreRef.current) return;
      loadMoreRef.current = true;
      setLoading(true);  
      console.log("loadmore order direction",activeTab, order);    
      fetchPositions(page, limit, activeTab, order)
        .then((response) => {
          //setPositions(prevPositions => [...prevPositions, ...response.data]);
          setPositions(response.data);
          setPagination(response.pagination);
        })
        .catch((error) => {
          setError('Failed to fetch positions. Please try again.');
        })
        .finally(() => {
          setLoading(false);
          loadMoreRef.current = false;
        });
    }, [fetchPositions]);
  
    useEffect(() => {
      //console.log("selectedPage, itemsPerPage",selectedPage, itemsPerPage);
      console.log("direction at liquidationPage", direction);
      loadMoreData(selectedPage, itemsPerPage, activeTab, direction);
    }, [loadMoreData]);
    
    
    //console.log("LiquidationPage page",positions);
    const [contracts, setContracts] = useState<any[]>([])

  // useEffect(() => {
    
  //   if (address && isConnected && positions.length > 0) {
  //     const newContracts = positions.map((item) => (        
  //       {
  //       address: LIQUIDATE_CONTRACT_ADDRESS,
  //       abi: Margin as Abi,
  //       functionName: "canLiquidate",
  //       args: [address as `0x${string}`, item.user],
  //     }))
  //     setContracts(newContracts)
  //   }
  // }, [address, isConnected, positions])

  // const { data:currentmrData, isLoading, isError } = useContractReads({
  //   contracts: contracts,
  //   enabled: !!address && isConnected && contracts.length > 0,
  //   watch: true,
  // })

  const contractCalls = useMemo(() => {
    if (!address || !isConnected || positions.length === 0) return [];

    return positions.map((position) => ({
      address: LIQUIDATE_CONTRACT_ADDRESS,
      abi: Margin as Abi,
      functionName: 'canLiquidate',
      args: [address, position.user],
    }));
  }, [address, isConnected, positions]);

  const { data: currentmrData, isError, isLoading } = useContractReads({
    contracts: contractCalls,
    enabled: contractCalls.length > 0,
  });
  //console.log("canliq data",data);
  // useEffect(() => {
  //   if (currentmrData && !isLoading && !isError) {
  //     const updatedPositions = positions.map((position, index) => ({
  //       ...position,
  //       canLiquidate: currentmrData[index].result as boolean,
  //     }))
  //     setPositions(updatedPositions)
  //   }
    
  // }, [currentmrData, isLoading, isError])
  useEffect(() => {
    console.log("currentmrData", currentmrData);
    if (currentmrData && !isLoading && !isError) {
      const updatedPositions = positions.map((position, index) => {
        const canLiquidate = currentmrData[index]?.status === 'success' && 
                             currentmrData[index]?.result !== undefined ? 
                             !!currentmrData[index].result : false;
        
        const newPosition = {
          ...position,
          canLiquidate,
        }
        console.log(`Updating position for user ${position.user}:`, newPosition)
        return newPosition
      })
      console.log('All updated positions:', updatedPositions)
      setPositions(updatedPositions)
    }
  }, [currentmrData, isLoading, isError])
 // console.log("positions",positions);
    return (
      <LiquidationTablePage
      tableData={positions}
      pageLen={pagination.totalPages}
      itemLen={pagination.totalItems}
      loading={loading}
      onLoadMore={loadMoreData}
    />
      
    );
};

export default LiquidationPage;
